// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  client: 'MYBOOKIE',
  env: 'LOCAL',
  MYBOOKIE: {
    gameList: `https://myb-vip-gms.web.app/api`,
    //gameList: `http://190.4.92.165:8080/api`,
    domain: '/api',
    homeURL: 'https://myb-vip.web.app/',
    casinoUrl:'https://mybookie-engine-qa.engine.biz/casino',
    webDomain: 'https://engine.mybookie.ag',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
